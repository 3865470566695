import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import experienceData from '../data/ExperienceData';
import courseData from '../data/CoursesData';
import SchoolIcon from '@mui/icons-material/School';

export default function ExperiencePage({ scrollPosition }) {
    const { id } = useParams(); // Get the ID from the URL
    const experienceIndex = parseInt(id, 10) - 1;
    const experiencePageRef = useRef(null);
    const [expandedIndex, setExpandedIndex] = useState(null);

    const experience = experienceData[experienceIndex];

    useEffect(() => {
        if (experience && experiencePageRef.current) {
            const experiencePageContainerRef = experiencePageRef.current;
            experiencePageContainerRef.style.transition = "transform 0.3s ease-out";

            if (window.innerWidth < 640) { // small screen
                experiencePageContainerRef.style.transform = `translateX(${scrollPosition - 380}px)`;

            } else { // large screen
                experiencePageContainerRef.style.transform = `translateX(${scrollPosition - 360}px)`;
            }
        }
    }, [experience, scrollPosition]);

    if (!experience) {
        return <div>Experience not found.</div>; // Handle invalid IDs
    }

    return (
        <div className="flex flex-col flex-nowrap min-w-max h-full relative top-[1.5rem] h-sm:top-1 h-sm:space-y-2 lg:top-[3rem] space-y-4 lg:space-y-4" ref={experiencePageRef}>

            {/* main header section */}
            <div className=" flex pb-4 h-sm:pb-1 pr-4 space-x-4 max-w-full">
                {/* Image container with fixed width */}
                <div className=" h-16 w-16 mt-2 lg:w-20 lg:h-20">
                    {experience.logo ? (
                        <img
                            src={experience.logo}
                            alt="Logo"
                            className="object-cover w-full h-full rounded-xl"
                        />
                    ) : (
                        <SchoolIcon style={{ fontSize: '3rem' }} />
                    )}
                </div>
                <div className="space-y-1 h-auto w-[70%] flex flex-col justify-center tracking-wide">
                    <h1 className="text-[1rem] lg:text-lg whitespace-nowrap tracking-widest">{experience.title}</h1>
                    <h2 className="text-[0.8rem] lg:text-sm">{experience.company}</h2>
                    <p className="text-[0.6rem] lg:text-sm text-lineBlue">
                        {experience.startDate} {experience.startDate && ' - '} {experience.endDate}
                    </p>
                </div>

            </div>
            <div className="flex space-x-12 lg:space-x-16 whitespace-break-spaces tracking-wider font-thin leading-7 lg:leading-9 text-[0.9rem] h-md:text-[0.7rem] h-sm:text-[0.7rem] h-sm:leading-1 lg:text-base text-ellipsis">
                <div className="h-full max-w-[26rem] lg:max-w-[45rem] h-sm:max-w-[30rem] ">
                    <p className=" ">{experience.shortDescription}</p>
                </div>

                <div className="h-full pb-4 max-w-[26rem] lg:max-w-[45rem] h-sm:max-w-[30rem]">
                    <p className="">{experience.fullDescription1}</p>
                </div>
                <div className="h-full pb-4 max-w-[26rem] lg:max-w-[45rem] h-sm:max-w-[30rem] ">
                    <p className="">{experience.fullDescription2}</p>
                </div>


                {experience.experience_name === "uofc-computer-science" && (
                    <>
                        <div className="h-full pb-4 max-w-[26rem] lg:max-w-[45rem] h-sm:max-w-[30rem]">
                            <p className="">{experience.fullDescription3}</p>
                        </div>

                        <div className="h-full pb-4 max-w-[26rem] lg:max-w-[45rem] h-sm:max-w-[30rem]">
                            <p className="">{experience.fullDescription4}</p>
                        </div>

                        <div className="w-[240rem] flex flex-wrap gap-5 leading-none">
                            {courseData.map((course, index) => (
                                <div
                                    key={index}
                                    onClick={() => setExpandedIndex(expandedIndex === index ? null : index)} // Toggle expand
                                    className="rounded-xl shadow-2xl drop-shadow-md bg-darkBackground px-4 pb-2 space-y-2 overflow-y-hidden text-dirtyWhite hover:shadow-2xl transition-shadow cursor-pointer w-72 lg:w-92 h-sm:w-56 h-sm:h-20"
                                // style={{height: expandedIndex === index ? "auto" : "90px" }}
                                >

                                    <h2 className="font-semibold text-sm h-sm:text-[0.6rem]">{course.courseName}</h2>
                                    <p className="whitespace-nowrap font-semibold text-gray-600 tracking-widest text-[0.5rem]">{course.courseCode}</p>

                                    <p className={`text-[0.65rem] text-dirtyWhite leading-3 h-sm:text-[0.5rem] ${expandedIndex === index ? '' : 'line-clamp-2'}`}>
                                        {course.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div >
    );
}
