import React, { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Timeline from './components/Timeline';
import Home from './pages/Home';
import ExperiencePage from './pages/ExperiencePage';
import HomeIcon from '@mui/icons-material/Home';
import LeftSide from './components/LeftSide';


function ScrollToTop({ containerRef }) {
  const location = useLocation();

  const getLeftScrollPosition = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 640) return 430; // Mobile (sm)
    return 390; // Large Desktop (xl and above)
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (containerRef.current) {
        containerRef.current.scrollTo({ left: getLeftScrollPosition(), behavior: 'smooth' });
      }
    }
  }, [location, containerRef]);

  return null;
}


function HomeButton() {
  const navigate = useNavigate();

  return (
    <HomeIcon
      className="fixed top-4 ml-4 lg:ml-9 cursor-pointer text-dirtyWhite"
      sx={{
        fontSize: {
          xl: 40,  // 80px for extra-large screens
        },
      }}
      onClick={() => navigate('/')}
    />
  );
}

function App() {
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  let baseLeft = 30;

  const handleWheelScroll = (e) => {
    if (containerRef.current) {
      e.preventDefault();
      containerRef.current.scrollLeft += e.deltaY; // Map vertical scroll to horizontal scroll
    }
  };


  return (
    <Router>
      <ScrollToTop containerRef={containerRef} />
      <div
        className=" h-screen w-screen overflow-y-hidden flex bg-darkBackground text-dirtyWhite touch-pan-x scroll-auto font-customFont scrollbar-container"
        ref={containerRef}
        onWheel={handleWheelScroll}
      >
        <div className="flex flex-col justify-between min-w-full  pt-10">
          <HomeButton />

          <div className="flex min-w-full h-[70%] lg:h-[80%] overflow-y-clip">
            <LeftSide />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/experience/:id" element={<ExperiencePage scrollPosition={scrollPosition} />} />
            </Routes>
          </div>

          <div className='w-full flex-1 flex flex-nowrap min-w-[165rem] max-h-[30%] lg:max-h-[20%]'>
            <Timeline containerRef={containerRef} baseLeft={baseLeft} setScrollPosition={setScrollPosition} />
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
