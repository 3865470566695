const courseData = [
    {
        courseName: "Computer Science for Majors",
        courseCode: "CPSC 231",
        description: "Introduction to problem solving, the analysis and design of small-scale computational systems, and implementation using a procedural programming language."
    },
    {
        courseName: "Linear Algebra",
        courseCode: "MATH 211",
        description: "Introduction to systems of linear equations, vectors in Euclidean space and matrix algebra. Linear transformations, determinants, complex numbers, eigenvalues, and applications."
    },
    {
        courseName: "Introductory Calculus",
        courseCode: "MATH 249",
        description: "Introduction to single variable calculus. Limits, derivatives and integrals of algebraic, exponential, logarithmic and trigonometric functions play a central role. Applications of differentiation; the fundamental theorem of calculus, improper integrals and applications of integration."
    },
    {
        courseName: "Introduction to Computer Science for Majors II",
        courseCode: "CPSC 233",
        description: "Emphasis on object-oriented analysis and design of small-scale computational systems and implementation using an object oriented language. Issues of design, modularization, and programming style."
    },
    {
        courseName: "University Calculus II",
        courseCode: "MATH 267",
        description: "A concluding treatment of single variable calculus and an introduction to calculus in several variables. Single variable calculus: techniques of integration, sequences, series, convergence tests, and Taylor series. Calculus of several variables: partial differentiation, multiple integration, parametric equations, and applications."
    },
    {
        courseName: "Logic I",
        courseCode: "PHIL 279",
        description: "Study of formal logic principles."
    },
    {
        courseName: "Computing Machinery",
        courseCode: "CPSC 355",
        description: "An introduction to computing machinery that explores how programs in a compiled language connect to assembly language and machine code, and how they execute on hardware. This course includes an in-depth study of modern CPU architecture, covering its assembly language, internal data representation, and the link between high-level programming concepts and machine operations."
    },
    {
        courseName: "Introduction to Business Venturing",
        courseCode: "ENTI 201",
        description: "Basics of starting and managing a business venture."
    },
    {
        courseName: "Theoretical Foundations of Computer Science",
        courseCode: "CPSC 251",
        description: "Proof techniques. Recursion and iteration. Specification of algorithmic problems and fundamental proof techniques in Computer Science. Discrete structures such as graphs, trees, strings, functions, and their computer representation. Counting, permutations. Random events. Conditional events. Applications in Computer Science."
    },
    {
        courseName: "Thinking with Data",
        courseCode: "DATA 201",
        description: "Introduction to tools and techniques for managing, visualizing, and making sense of data. Includes an introduction to data cleaning, basic statistics, exploratory visualization, sensemaking, and data presentation."
    },
    {
        courseName: "Information Technology Ethics",
        courseCode: "PHIL 314",
        description: "Exploration of ethical issues in IT."
    },
    {
        courseName: "Theoretical Foundations of Computer Science II",
        courseCode: "CPSC 351",
        description: "Advanced theoretical concepts in computer science."
    },
    {
        courseName: "Computing Machinery II",
        courseCode: "CPSC 359",
        description: "Hardware and microprocessor design, including the connection between gate-level digital logic circuits and sequential machines that can execute an algorithm and perform input and output."
    },
    {
        courseName: "Principles of Microeconomics",
        courseCode: "ECON 201",
        description: "Introduction to microeconomic theory."
    },
    {
        courseName: "Introduction to Software Engineering",
        courseCode: "SENG 300",
        description: "Introduction to the development and evolution of software. Covers key conceptual foundations as well as key methods and techniques used in the different phases of the software lifecycle. Emphasis on both technical and soft skills needed for high quality software and software-based products developed in teams."
    },
    {
        courseName: "Data Structures, Algorithms & Analysis",
        courseCode: "CPSC 331",
        description: "Fundamental data structures, including arrays, lists, stacks, queues, trees, hash tables, and graphs. Algorithms for searching and sorting. Introduction to the correctness and analysis of algorithms. For computer science majors and those interested in algorithm design and analysis, information security, and other mathematically-intensive areas."
    },
    {
        courseName: "Programming Paradigms",
        courseCode: "CPSC 449",
        description: "Examination of the basic principles of the major programming language paradigms. Focus on declarative paradigms such as functional and logic programming. Data types, control expressions, loops, types of references, lazy evaluation, different interpretation principles, information hiding."
    },
    {
        courseName: "Principles of Operating Systems",
        courseCode: "CPSC 457",
        description: "An introduction to operating systems principles. Performance measurement; concurrent programs; the management of information, memory and processor resources."
    },
    {
        courseName: "Database Management Systems",
        courseCode: "CPSC 471",
        description: "Conceptual, internal and external data bases. Relational data base systems and SQL. The normal forms, data base design, and the entity-relationship approach."
    },
    {
        courseName: "Introduction to Energy",
        courseCode: "PHYS 371",
        description: "Energy and power. Sources of energy such as wind power, solar power, nuclear power, geothermal energy and fossil fuels and related limitations. Generation and distribution of electricity."
    },
    {
        courseName: "Design & Analysis of Algorithms I",
        courseCode: "CPSC 413",
        description: "Techniques for the analysis of algorithms, including counting, summation, recurrences, and asymptotic relations; techniques for the design of efficient algorithms, including greedy methods, divide and conquer, and dynamic programming; examples of their application; an introduction to tractable and intractable problems."
    },
    {
        courseName: "Creativity",
        courseCode: "DRAM 203",
        description: "Exploration of creativity in various contexts."
    },
    {
        courseName: "Language and Power",
        courseCode: "LING 309",
        description: "Examination of language and its social implications."
    },
    {
        courseName: "Computer Networks",
        courseCode: "CPSC 441",
        description: "Principles and practice in modern telecommunications, computer communications and networks. Layered communication protocols and current physical, data link, network and Internet protocol layers. Circuit switching, packet switching, and an introduction to broadband multimedia networking."
    },
    {
        courseName: "Innovation-Driven Entrepreneurship",
        courseCode: "ENTI 381",
        description: "Hands-on overview of the processes involved in evaluating an innovative business idea for a start-up using a case: team formation, customer discovery-target market analysis, product-industry feasibility and business model validation."
    },
    {
        courseName: "Software Project Management",
        courseCode: "SENG 511",
        description: "Analysis of methods, tools, and techniques for software process improvement and software project management as an effort to achieve quality software products"
    },
    {
        courseName: "Web-Based Systems",
        courseCode: "SENG 513",
        description: "Development of web-based software systems."
    },
    {
        courseName: "Scalable Data Analytics",
        courseCode: "SENG 550",
        description: "Sources and characteristics of large scale data, i.e., `big data`, large scale data analysis, benefits of large scale data analysis to various industry domains, programming paradigms and middleware technologies for scalable data analysis, algorithms that enable large scale data processing, application of large scale data algorithms in selected application domains, e.g., Web user behaviour analysis and text processing, analytics frameworks."
    },
    {
        courseName: "Information Security & Privacy",
        courseCode: "CPSC 329",
        description: "An introduction to concepts in information security and privacy."
    },
    {
        courseName: "Human-Computer Interaction I",
        courseCode: "CPSC 481",
        description: "Principles and techniques for effective human-computer interaction."
    },
    {
        courseName: "AI, Games and Drama",
        courseCode: "DRAM 574",
        description: "Exploration of AI and games within the context of drama."
    },
    {
        courseName: "Principles of Macroeconomics",
        courseCode: "ECON 203",
        description: "Introduction to macroeconomic theory."
    },
    {
        courseName: "Software Architecture",
        courseCode: "SENG 401",
        description: "Software architectures and design for non-functional software properties. Introduction to program comprehension skills including analysis of existing architectures."
    },
    {
        courseName: "Software Requirements Engineering",
        courseCode: "SENG 471",
        description: "Principles of gathering and managing software requirements."
    },
    {
        courseName: "Business Ethics",
        courseCode: "PHIL 329",
        description: "Study of ethical issues in business."
    }
];

export default courseData;
