import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';


export default function Experience({ id, experience_name, title, company, logo, address, startDate, includeStartDate = true, endDate, baseLeft, horizontalWidth, verticalHeight, isActive }) {
    const navigate = useNavigate();
    const location = useLocation();

    const isHomePage = location.pathname === '/' || location.pathname === '/home';

    return (
        <div
            className={`relative transition-all duration-300`}
            onClick={() => navigate(`/experience/${id}`)}
            style={{
                cursor: 'pointer',
                opacity: isHomePage || isActive ? 1 : 1,
                transition: 'opacity 0.3s ease, filter 0.3s ease',  // Separate transitions for opacity and blur
                transform: 'translateZ(0)',  // Force GPU rendering for better performance in Safari
                filter: isHomePage || isActive ? 'blur(0px)' : 'blur(2px)'
            }}
        >
            {/* Timeline vertical line */}
            <div className='absolute bg-white'
                style={{ left: `${baseLeft}rem`, bottom: '0', width: '0.1rem', height: '1rem' }}>
            </div>

            {/* Content section */}
            <div className='absolute bottom-5 space-y-[0.1rem] lg:space-y-[0.3rem]' style={{ left: `${baseLeft}rem`, width: 'max-content' }}>
                {/* Company logo */}
                {logo ? (
                    <img src={logo} alt="Company Logo" className='w-auto h-7 lg:h-10 lg:mb-3 mb-2 rounded-sm' />
                ) : (
                    <SchoolIcon
                        className="text-sm sm:text-base lg:text-xl mb-2"
                    />
                )}
                {/* Job title */}
                <h2 className='text-dirtyWhite text-[0.6rem] lg:text-[0.8rem] lg:font-thin tracking-wide' style={{ fontWeight: 600 }}>{title}</h2>

                {/* Company name */}
                <h3 className='text-dirtyWhite text-[0.6rem] lg:text-[0.7rem]' style={{ fontWeight: 500 }}>{company}</h3>

                {/* address */}
                {/* <p className='text-dirtyWhite text-xs'>{address}</p> */}

                {/* End Date - left side */}
                <p className='text-lineBlue text-[0.6rem] lg:text-[0.7rem]'>{endDate}</p>
            </div>

            {/* Time section */}

            {/* Vertical start */}
            <div className='absolute bg-lineBlue'
                style={{ top: '0', left: `${baseLeft}rem`, width: '0.07rem', height: `${verticalHeight}rem` }}>
            </div>

            {/* Horizontal */}
            <div className='absolute bg-lineBlue'
                style={{ top: `${verticalHeight}rem`, left: `${baseLeft}rem`, width: `${horizontalWidth}rem`, height: '0.07rem' }}>
            </div>

            {/* Vertical finish */}
            <div className='absolute bg-lineBlue'
                style={{ top: '0rem', left: `${baseLeft + horizontalWidth - 0.07}rem`, width: '0.07rem', height: `${verticalHeight}rem` }}>
            </div>

            {/* Start Date - right side */}
            {includeStartDate &&
                <h3 className='text-[0.6rem] lg:text-[0.7rem] text-lineBlue font-normal w-max absolute'
                    style={{ top: '-1.5rem', left: `${baseLeft + horizontalWidth - 0.12}rem` }}>
                    {startDate}
                </h3>
            }
        </div >
    );
}
