import React from 'react';

export default function Dot({ color = 'white', size = '0.3rem' }) {
    return (
        <div
            className="rounded-full mx-3"
            style={{
                backgroundColor: color,
                width: size,
                height: size,
            }}
        ></div>
    );
}
